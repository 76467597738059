/* eslint-disable */

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import { loadFonts } from './plugins/webfontloader'
import router from "./router"
/* eslint-disable */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYl-fSYgfhuehcrQ3Xc2UDYy42aPmzVho",
  authDomain: "dgm-monitoring.firebaseapp.com",
  projectId: "dgm-monitoring",
  storageBucket: "dgm-monitoring.appspot.com",
  messagingSenderId: "394529280243",
  appId: "1:394529280243:web:97fc58c4bfb713b984a786",
  measurementId: "G-MYECKPLPH4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

createApp(App).use(router).use(vuetify).mount('#app')

loadFonts();
