import {createRouter, createWebHistory } from "vue-router";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/",name:'DGM Monitoring - Sign In', component: () => import("@/views/SignInForm.vue") },
        { path: "/monitoring/:serverName", name: 'TicksTable', component: () => import("@/views/TicksTable.vue"), props: true },
        //{ path: "/statistics",name: 'Ticks Statistics', component: () => import("@/views/Dashboard.vue")},
        { path: "/settings/:serverName",  name: 'SettingsPage', component: () => import("@/views/SettingsPage.vue"), props: true},
        { path: "/servers", name: 'DGM Monitoring - Servers List', component: () => import("@/views/ServersList.vue") },
    ],
});

export default router;