<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
 /* eslint-disable */
import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { initializeApp } from "@firebase/app";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore"; // Import getFirestore
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAYl-fSYgfhuehcrQ3Xc2UDYy42aPmzVho",
  authDomain: "dgm-monitoring.firebaseapp.com",
  projectId: "dgm-monitoring",
  storageBucket: "dgm-monitoring.appspot.com",
  messagingSenderId: "394529280243",
  appId: "1:394529280243:web:97fc58c4bfb713b984a786",
  measurementId: "G-MYECKPLPH4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    
    onBeforeMount(() => {
      onAuthStateChanged(auth, (user) => {
        //console.log("Auth state changed:", user);
        if (!user) {
          //console.log("Redirecting to /");
          router.replace('/');
        } else if (route.path === "/") {
          //console.log("Redirecting to /feed");
          router.replace('/servers');
        }
      });
    });
  }
}

</script>
